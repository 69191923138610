








































































import { Vue, Component, Prop } from "vue-property-decorator";
import { ShippingFeeInfoProperties } from "./ShippingFeeInfoView.vue";
import { ShippingFeeRegionDialogProperties } from "./ShippingFeeRegionDialogView.vue";

@Component({
    data: () => ({
        table: {
            pageNo: 1,
            pageCount: 1,
            recordCount: 20,
            recordCounts: [10, 20, 30, 40, 50],
        },
        filter: {
            country: "",
            state: "",
            city: "",
        }
    }),
})
export default class ShippingFeeRegionView extends Vue {
    @Prop() private properties: ShippingFeeInfoProperties;
    @Prop() private shippingFeeRegionDialog: ShippingFeeRegionDialogProperties;

    public get shippingFee() {
        return this.properties.shippingFee;
    }

    public get headers() {
        return [
            {
                text: this.$t("text.country"),
                value: "countryText",
                filter: (v) => {
                    if (!this.$data.filter.country) return true;
                    return v.toLowerCase().includes(this.$data.filter.country.toLowerCase());
                },
            },
            {
                text: this.$t("text.state"),
                value: "stateText",
                filter: (v) => {
                    if (!this.$data.filter.state) return true;
                    return v.toLowerCase().includes(this.$data.filter.state.toLowerCase());
                },
            },
            {
                text: this.$t("text.city"),
                value: "cityText",
                filter: (v) => {
                    if (!this.$data.filter.city) return true;
                    return v.toLowerCase().includes(this.$data.filter.city.toLowerCase());
                },
            },
            {
                text: this.$t("text.actions"),
                value: "action",
                align: "center",
                fixed: true,
                width: "120px",
                sortable: false,
            },
        ];
    }

    public get footerProps() {
        return {
            "items-per-page-options": this.$data.table.recordCounts,
        };
    }

    public created() {
        this.properties.events.subscribe("sort-regions", this.sort);
    }

    public destroyed() {
        this.properties.events.remove("sort-regions", this.sort);
    }

    public sort() {
        const regions: any[] = this.properties.shippingFee.regions;
        regions.sort((lhs, rhs) => {
            var i = lhs.countryText.toLowerCase().localeCompare(rhs.countryText.toLowerCase());
            if (i !== 0) {
                if (lhs.countryText === "All") i = -1;
                if (rhs.countryText === "All") i = 1;
                return i;
            }
            i = lhs.stateText.toLowerCase().localeCompare(rhs.stateText.toLowerCase());
            if (i !== 0) {
                if (lhs.stateText === "All") i = -1;
                if (rhs.stateText === "All") i = 1;
                return i;
            }
            i = lhs.cityText.toLowerCase().localeCompare(rhs.cityText.toLowerCase());
            if (lhs.cityText === "All") i = -1;
            if (rhs.cityText === "All") i = 1;
            return i;
        });
    }

    public create() {
        this.shippingFeeRegionDialog.allCountries = true;
        this.shippingFeeRegionDialog.allStates = true;
        this.shippingFeeRegionDialog.allCities = true;
        this.shippingFeeRegionDialog.selectedCountries = [];
        this.shippingFeeRegionDialog.selectedStates = [];
        this.shippingFeeRegionDialog.selectedCities = [];
        this.shippingFeeRegionDialog.visible = true;
    }

    public remove(item: any) {
        const regions: any[] = this.properties.shippingFee.regions;
        regions.splice(regions.indexOf(item), 1);
    }
}
