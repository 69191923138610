var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"pt-3",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pt-2",attrs:{"cols":"8"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.shippingFee.regions,"items-per-page":_vm.table.recordCount,"page":_vm.table.pageNo,"loading":_vm.table.loading,"footer-props":_vm.footerProps,"disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"header.action",fn:function(){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.create()}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("text.add-region")))])])]},proxy:true},{key:"body.prepend",fn:function(ref){
var headers = ref.headers;
return [_c('tr',[_c('td',[_c('v-text-field',{staticClass:"mt-1 mb-2",attrs:{"hide-details":"","dense":""},model:{value:(_vm.filter.country),callback:function ($$v) {_vm.$set(_vm.filter, "country", $$v)},expression:"filter.country"}})],1),_c('td',[_c('v-text-field',{staticClass:"mt-1 mb-2",attrs:{"hide-details":"","dense":""},model:{value:(_vm.filter.state),callback:function ($$v) {_vm.$set(_vm.filter, "state", $$v)},expression:"filter.state"}})],1),_c('td',[_c('v-text-field',{staticClass:"mt-1 mb-2",attrs:{"hide-details":"","dense":""},model:{value:(_vm.filter.city),callback:function ($$v) {_vm.$set(_vm.filter, "city", $$v)},expression:"filter.city"}})],1),_c('td',{attrs:{"colspan":headers.length - 3}})])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":""},on:{"click":function($event){return _vm.remove(item)}}},on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("text.delete")))])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }